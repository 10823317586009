<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    :class="{ 'sticky-top': isSticky }"
  >
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img v-bind:src="logoPath" class="logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/about" class="nav-link text-black"
              >About</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/services" class="nav-link text-black"
              >Services</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/works" class="nav-link text-black"
              >Works</router-link
            >
          </li>
          <li class="nav-item">
            <a href="https://monayemislam.me/blog" class="nav-link text-black">Blog</a>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link text-black"
              >Contact</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logoPath: require("@/assets/logo.png"),
      isSticky: false,
    };
  },
  mounted() {
    // Add a scroll event listener to make the navbar sticky when scrolling
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Remove the scroll event listener when the component is unmounted
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // You can adjust the scroll threshold as needed
      const scrollThreshold = 300; // Adjust this value
      if (window.scrollY > scrollThreshold) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
};
</script>

<style>
/* Styling for the sticky navbar */
.sticky-top {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  background-color: white; /* Adjust the background color as needed */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a visual effect */
}

.logo {
  height: auto;
  width: 150px;
}

.navbar-parent ul li a {
  color: var(--global-white);
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.navbar-parent ul li a:hover {
  color: var(--global-yellow);
}

.navbar-parent ul li a:active {
  color: var(--global-yellow) !important;
}

.navbar-parent ul li a:focus {
  color: var(--global-yellow) !important;
}

.nav-title {
  color: var(--global-white) !important;
}
</style>
