<template>
  <div class="container mt-5">
    <h1 class="text-center mb-4">Comprehensive Solutions Tailored for You</h1>
    <p class="text-center">
      Come and explore web solutions that are uniquely yours. I'm here to turn
      your vision into reality, be it a website, e-commerce platform, or a
      cutting-edge web app. Let's create together!
    </p>

    <div class="row">
      <!-- Web Development Services -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-1">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-code"></i> Web Development Services
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Custom Website Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> E-commerce Website Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Content Management Systems (CMS)
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Web Application Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Mobile-Friendly (Responsive) Design
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Website Maintenance and Support
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Website Performance Optimization
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Backend Development -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-2">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-server"></i> Backend Development
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Server Setup and Configuration
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> API Development and Integration
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Database Design and Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Content Management System (CMS)
                Backend
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Payment Gateway Integration
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> User Authentication and
                Authorization
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Frontend Development -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-3">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-desktop"></i> Frontend Development
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Responsive Web Design
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> User Interface (UI) Design
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Single Page Applications (SPAs)
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Cross-Browser Compatibility
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Interactive Web Elements
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Accessibility (WCAG) Compliance
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- E-commerce Solutions -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-4">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-shopping-cart"></i> E-commerce Solutions
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Online Store Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Shopping Cart Integration
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Payment Gateway Integration
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Inventory Management
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Product Search and Filtering
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Security and SSL Implementation
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Database Services -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-5">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-database"></i> Database Services
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Database Design and Modeling
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Database Optimization
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Data Migration and ETL
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Database Security
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> NoSQL Database Solutions (MongoDB,
                Firebase)
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Web Security Services -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-6">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-shield-alt"></i> Web Security Services
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Vulnerability Assessment
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Security Audits and Testing
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> SSL Certificate Installation
              </li>
              <li class="pt-2"><i class="fas fa-minus"></i> Data Encryption</li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Firewall Setup and Monitoring
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Content Management Services -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-7">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-file-alt"></i> Content Management Services
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Custom CMS Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> WordPress Theme and Plugin
                Development
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> CMS Integration and Customization
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> User Training and Documentation
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Content Migration Services
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Web Hosting and Deployment -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-8">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-cloud"></i> Web Hosting and Deployment
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Server Selection and Setup
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Cloud Hosting Solutions (AWS,
                Azure, Google Cloud)
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Deployment Automation (CI/CD)
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Scalability and Load Balancing
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Server Monitoring and Maintenance
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Consultation and Training -->
      <div
        class="col-lg-4 col-md-6 mb-4"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div class="card h-100 card-color-9">
          <div class="card-body">
            <h5 class="card-title">
              <i class="fas fa-graduation-cap"></i> Consultation and Training
            </h5>
            <ul class="list-unstyled">
              <li class="pt-2">
                <i class="fas fa-minus"></i> Technology Consulting
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Training Workshops for Teams
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Performance Optimization
                Consultation
              </li>
              <li class="pt-2">
                <i class="fas fa-minus"></i> Web Development Training
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--CTA start-->
  <div class="bg-white">
    <!-- CTA Section -->
    <section class="cta-section py-5 container">
      <div class="row">
        <!-- First Column (Title and Text) -->
        <div class="col-md-6">
          <h2 class="section-title">Need any solutions?</h2>
          <p class="section-text">
            Your web journey is as unique as your ideas. Join me in crafting a
            digital solution that mirrors your vision. Let's connect and get
            started on your path to web success.
          </p>
        </div>
        <!-- Second Column (CTA Button) -->
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <a href="/contact" class="btn btn-lg cta-btn-about-me">Let's Begin</a>
        </div>
      </div>
    </section>
  </div>
  <!--CTA End-->
</template>
  <style>
.cta-btn-about-me {
  background-color: #ff4c60 !important;
  color: #ffffff !important;
}
.cta-btn-about-me:hover {
  color: #ff4c60 !important;
  background-color: #ffffff !important;
  border: 1px solid #ff4c60 !important;
}
</style>