import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import About from '@/views/AboutView.vue'
import Blog from '@/views/BlogView.vue'
import Contact from '@/views/ContactView.vue'
import Services from '@/views/ServicesView.vue'
import Works from '@/views/WorksView.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/services',
        name: 'Services',
        component: Services
    },
    {
        path: '/works',
        name: 'works',
        component: Works
    }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
