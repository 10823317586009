<script>
export default {
  data() {
    return {
      imagePath: require("@/assets/monayem-islam.png"),
    };
  },
};
</script>
<template>
  <!--image and name start -->
  <div
    class="container-fluid bg-light container-home-box d-flex justify-content-center align-items-center"
  >
    <div class="container">
      <div
        class="row g-5 d-flex align-items-center justify-content-center name-image-box pt-0"
      >
        <div
          class="col-md-4 col-12"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <div class="d-flex justify-content-center">
            <img :src="imagePath" class="img-fluid rounded border tamal_img" />
          </div>
          <div class="d-flex justify-content-center">
            <h2 class="pt-3 text-center myName">Monayem Islam</h2>
          </div>
          <div class="d-flex justify-content-center">
            <div class="border rounded">
              <a href="https://www.linkedin.com/in/monayemislam" target="_blank"
                ><i class="fab fa-linkedin fa-2x p-2"></i
              ></a>
              <a
                href="https://stackoverflow.com/users/11581254/monayem-islam"
                target="_blank"
                ><i class="fab fa-stack-overflow fa-2x p-2"></i
              ></a>
              <a href="https://github.com/monayemislam" target="_blank"
                ><i class="fab fa-github fa-2x p-2"></i
              ></a>
              <a href="https://monayemislam.medium.com" target="_blank"
                ><i class="fab fa-medium fa-2x p-2"></i
              ></a>
              <a href="https://www.facebook.com/monayem.islam.7" target="_blank"
                ><i class="fab fa-facebook-square fa-2x p-2"></i
              ></a>
              <a href="https://twitter.com/monayemislam" target="_blank"
                ><i class="fab fa-twitter-square fa-2x p-2"></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="console d-sm-block col-md-8 col-12"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <div class="console-title d-flex justify-content-between">
            <div>about_monayem.json</div>
            <div>
              <i class="fa-solid fa-minus pe-3"></i>
              <i class="fa-regular fa-square pe-3"></i>
              <i class="fa-solid fa-xmark pe-3"></i>
            </div>
          </div>
          <pre class="output">
  {
    "<span class="property">monayem_islam</span>": {
      "<span class="property">who_am_I</span>": "<span class="value">full-stack web developer</span>",
      "<span class="property">email</span>": "<span class="value">monayemislam@gmail.com</span>",
      "<span class="property">address</span>": {
        "<span class="property">current_address</span>": "<span class="value">Mirpur, Dhaka, Bangladesh</span>",
        "<span class="property">permanent_address</span>": "<span class="value">Chapainawabganj, Rajshahi, Bangladesh</span>"
      }
    }
  }
      </pre>
        </div>
      </div>
    </div>
  </div>

  <!--end of image and name-->
</template>

<style scooped>
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Carter+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@500&display=swap");

.container-home-box {
  height: calc(100vh - 86px);
}

.fa-facebook-square {
  color: #3b5998;
}

.fa-twitter-square {
  color: #00aced;
}

.fa-linkedin-square {
  color: #007bb6;
}

.fa-stack-overflow {
  color: #ef8236;
}

.background_1 {
  background-color: #18bc9c;
}

.tamal_img {
  width: 250px;
  height: auto;
}

.myName {
  font-family: "Zilla Slab", serif;
  font-size: 40px;
}

.articleTitle {
  font-family: "Lobster", cursive;
  font-size: 40px;
  color: #c6426e;
}

.leftBorder {
  border-left: 4px solid #c6426e;
}

.bg_crimson_tide {
  /* background: linear-gradient(to bottom, #ffffff, #f5f5f5); */
  background-color: bisque;
}

.console {
  background-color: black;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: white;
  font-family: monospace;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.console-title {
  background-color: var(--global-dark-one);
  padding: 10px 10px 10px 5px;
  color: #ffffff;
}

.output {
  /* overflow-y: auto; */
  padding: 20px;
}

.property {
  color: #c3e88d;
  font-size: 1.2rem;
}

.value {
  color: #f78c6c;
  font-size: 1.2rem;
}

pre {
  margin: 0;
}
@media (max-width: 576px) {
  .container-home-box {
    padding-top: 230px;
    margin-bottom: 230px;
  }
}
</style>