<template>
  <div class="row">
    <div class="col-12">
      <NavigationBar></NavigationBar>
    </div>

    <div class="col-12 bg-light">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavigationBar from "./components/common/NavigationBar.vue";

export default {
  name: "App",
  components: {
    NavigationBar,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;1,100&display=swap");

:root {
  --global-yellow: #ffd500;
  --global-light-gray: #e2e7e9;
  --global-dark-gray: #cccccc;
  --global-dark-one: #222b2f;
  --global-dark-two: #242a2e;
  --global-dodger-blue: #3691fc;
  --global-light-blue: #4fc3fc;
  --global-purple: #f53df5;
  --global-light-red: #fc4c4c;
  --global-white: #ffffff;
  --global-root-dark-bg: #1a2023;
  --main-color: #55527c;
  --dark-color: #130f49;
  --yellow-color: #ff972d;
  --blue-color: #a5a6ff;
}

#app {
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}
</style>
