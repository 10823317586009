<script>
export default {
  data() {
    return {
      vmsImagePath: require("@/assets/works/vms.png"),
      vmsDocumentationPath: "assets/document/vehicle-management-system.pdf",
      nybsysImagePath: require("@/assets/works/nybsys.png"),
      nybsysDocumentationPath: "assets/document/Nybsys_Website.pdf",
      e_councilorDocumentationPath: "assets/document/E_councilor.pdf",
      eCouncilorImagePath: require("@/assets/works/e_councilor.png"),
      alQuranDocumentationPath: "assets/document/AL_Quran.pdf",
      alQuranImagePath: require("@/assets/works/al-quran.png"),
      tcoCalculatorDocumentationPath: "assets/document/tco_calculator.pdf",
      tcoCalculatorImagePath: require("@/assets/works/tco_calculator.png"),
      facenextImagePath: require("@/assets/works/facenext.png"),
      centralKidsImagePath: require("@/assets/works/centralKids.png"),
      toronaLinkImagePath: require("@/assets/works/torona-link.png"),
      wssImagePath: require("@/assets/works/wss.png"),
      wssDocumentationPath: "assets/document/wss.pdf",
      geniusCareImagePath: require("@/assets/works/genius_care.png"),
      bpiplImagePath: require("@/assets/works/bpipl.png"),
      smartDustbinImagePath: require("@/assets/works/smart_dustbin.png"),
      smartDustbinDocumentationPath: "assets/document/smart_dustbin.pdf",
    };
  },
};
</script>

<template>
  <div class="container">
    <!-- Page Heading -->
    <h1 class="my-4 text-center">My Recent Projects</h1>

    <!-- Page Description -->
    <p class="mb-4 text-center">
      Here are some of the recent projects I've worked on. Each project
      showcases a combination of technologies and solutions aimed at addressing
      real-world challenges.
    </p>
    <div class="row row-cols-1 row-cols-md-3 g-4 pb-5">
      <!-- vms start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="vmsImagePath"
              class="card-img-top zoom-img"
              alt="Vehicle Management System"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Vehicle Management System</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">CodeIgniter</span>
              <span class="badge bg-ff4c60 me-1">Bootstrap</span>
              <span class="badge bg-ff4c60 me-1">PHP</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">Ajax</span>
              <span class="badge bg-ff4c60 me-1">JQuery</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              Developed the Vehicle Management System to avoid the
              time-consuming primitive solution. Reduced time and efforts of
              manpower, improved functionality, and increased efficiency.
              Serving in
              <a href="https://sqgc.com" target="_blank">SQ Group</a>
              successfully.
            </p>
            <div class="d-flex justify-content-between mt-auto">
              <a
                :href="vmsDocumentationPath"
                class="mt-auto link-unstyled"
                target="_blank"
              >
                <i class="fas fa-info-circle me-1"></i> Learn more
              </a>
              <a
                href="#vmsModal"
                data-bs-toggle="modal"
                data-bs-target="#vmsModal"
                class="mt-auto link-unstyled"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--vms end-->
      <!-- wss start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="wssImagePath"
              class="card-img-top zoom-img"
              alt="Wastage Separation System"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Waste Separation System</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Core PHP</span>
              <span class="badge bg-ff4c60 me-1">Bootstrap</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">Ajax</span>
              <span class="badge bg-ff4c60 me-1">JQuery</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              Waste Separation System is an innovative project designed to
              effectively manage waste based on various parameters such as waste
              type, preferred waste bin color, disposal organization, and more.
            </p>
            <div class="d-flex justify-content-between mt-auto">
              <a
                :href="wssDocumentationPath"
                class="mt-auto link-unstyled"
                target="_blank"
              >
                <i class="fas fa-info-circle me-1"></i> Learn more
              </a>
              <a
                href="#wssModal"
                data-bs-toggle="modal"
                data-bs-target="#wssModal"
                class="mt-auto link-unstyled"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--wss end-->
      <!-- E-councilor start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="eCouncilorImagePath"
              class="card-img-top zoom-img"
              alt="E-Councilor"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">E-Councilor</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Laravel</span>
              <span class="badge bg-ff4c60 me-1">Bootstrap</span>
              <span class="badge bg-ff4c60 me-1">PHP</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              The E-councilor project bridges the communication gap between
              residents and councilors. It empowers users to voice their
              concerns effectively, while councilors can efficiently ad- dress
              community issue
            </p>
            <div class="d-flex justify-content-between mt-auto">
              <a
                :href="e_councilorDocumentationPath"
                class="mt-auto link-unstyled"
                target="_blank"
              >
                <i class="fas fa-info-circle me-1"></i> Learn more
              </a>
              <a
                href="https://councilor.monayemislam.me"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--E-councilor end-->
      <!-- Nybsys start -->
      
      <!-- Nybsys End -->
      <!-- AL Quran start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="alQuranImagePath"
              class="card-img-top zoom-img"
              alt="Al Quran"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">AL QURAN</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Vue</span>
              <span class="badge bg-ff4c60 me-1">Bootstrap</span>
              <span class="badge bg-ff4c60 me-1">TypeScript</span>
              <span class="badge bg-ff4c60 me-1">API</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              The AL-QURAN project uses Vue to offer Quranic text and meanings
              via the AL-QURAN API (https://alquran.cloud/api). It facilitates
              reading the Quran in Arabic, surah meanings, revelation types, and
              ayah counts.
            </p>
            <div class="d-flex justify-content-between mt-auto">
              <a
                :href="alQuranDocumentationPath"
                class="mt-auto link-unstyled"
                target="_blank"
              >
                <i class="fas fa-info-circle me-1"></i> Learn more
              </a>
              <a
                href="https://al-quran.monayemislam.me"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--AL Quran project end-->
      <!-- TCO Calculator start -->
     
      <!--TCO calculator end-->
      <!-- FaceNext start -->
      
      <!--Facenext end-->
      <!-- Central Kids family day care start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="centralKidsImagePath"
              class="card-img-top zoom-img"
              alt="Al Quran"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">CENTRAL KIDS FAMILY DAY CARE</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Wordpress</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              Central kids and Genius family day care (Australia) is aimed at
              delivering quality and affordable childcare to build bright future
              of our children.
            </p>
            <div class="d-flex justify-content-end mt-auto">
              <a
                href="https://centralkidsfamilydaycare.com.au/"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--Central Kids family day care end-->
      <!-- Torona Link start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="toronaLinkImagePath"
              class="card-img-top zoom-img"
              alt="Al Quran"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Torona Link</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Wordpress</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              Developed ToronaLink's website, highlighting over a decade of web
              architecture and design expertise. Partner with us for digital
              success.
            </p>
            <div class="d-flex justify-content-end mt-auto">
              <a
                href="https://toronalink.com/"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--torona link end-->
      <!-- Genius care start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="geniusCareImagePath"
              class="card-img-top zoom-img"
              alt="Genius Care"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Genius Care</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Wordpress</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              Genius Care is a registered NDIS Provider with experience working
              within multicultural communities across South East Queensland.
            </p>
            <div class="d-flex justify-content-end mt-auto">
              <a
                href="https://geniuscarendis.com.au/"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--Genius care end-->
      <!-- BPIPL start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="bpiplImagePath"
              class="card-img-top zoom-img"
              alt="Genius Care"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Blue Planet Innovations Pty Limited</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Wordpress</span>
              <span class="badge bg-ff4c60 me-1">MySQL</span>
              <span class="badge bg-ff4c60 me-1">JavaScript</span>
              <span class="badge bg-ff4c60 me-1">CSS</span>
              <span class="badge bg-ff4c60 me-1">HTML</span>
            </p>
            <p class="card-text">
              Blue Planet Innovations Pty Limited is a cutting-edge software
              company, driving innovation and technology solutions for a
              brighter digital future.
            </p>
            <div class="d-flex justify-content-end mt-auto">
              <a
                href="https://blueplanetinnovations.com/"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--BPIPL end-->
      <!-- smart dustbin start -->
      <div class="col">
        <div class="card h-100 d-flex flex-column card-zoom">
          <div class="">
            <img
              :src="smartDustbinImagePath"
              class="card-img-top zoom-img"
              alt="Genius Care"
            />
          </div>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Smart Dustbin</h5>
            <p class="card-text">
              <span class="badge bg-ff4c60 me-1">Internet of Things (IoT)</span>
            </p>
            <p class="card-text">
              he Smart Dustbin is an automatic lid opener system for garbage. It
              utilizes an ultrasonic sensor to detect a person in front of it,
              causing the lid of the dustbin to open automatically.
            </p>
            <div class="d-flex justify-content-between mt-auto">
              <a
                :href="smartDustbinDocumentationPath"
                class="mt-auto link-unstyled"
                target="_blank"
              >
                <i class="fas fa-info-circle me-1"></i> Learn more
              </a>
              <a
                href="https://www.facebook.com/iu.ac.bd/posts/2393830573984224/"
                class="link-unstyled"
                target="_blank"
              >
                <i class="fas fa-external-link-alt me-1"></i> Web Link
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--smart dustbin end-->
    </div>
  </div>

  <!--Modals for Individual Projects Start-->
  <!--vms start-->
  <div class="modal" tabindex="-1" id="vmsModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-ff4c60 text-white">
          <h5 class="modal-title">Vehicle Management System</h5>
        </div>
        <div class="modal-body">
          <p>
            The "Vehicle Management System" application is hosted on SQ Group's
            internal server and is not accessible from outside their network due
            to confidentiality issues. As a result, I am unable to provide a
            live link for this project.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm close-btn"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--vms end-->
  <!--wss start-->
  <div class="modal" tabindex="-1" id="wssModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-ff4c60 text-white">
          <h5 class="modal-title">Waste Separation System</h5>
        </div>
        <div class="modal-body">
          <p>
            The "Waste Separation System" application is hosted on internal
            server of a company. It is not accessible from outside their network
            due to confidentiality issues. As a result, I am unable to provide a
            live link for this project.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm close-btn"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--wss end-->
  <!--Modals for Individual Projects End-->
</template>

<style scoped>
.bg-ff4c60 {
  background-color: #ff4c60;
}
a {
  text-decoration: none;
}
a:hover {
  color: #ff4c60;
}

/* Add CSS for the zoom effect */
.card-zoom {
  overflow: hidden;
  position: relative;
}

.card-zoom .zoom-img {
  transition: transform 0.3s;
  max-width: 100%;
  max-height: 100%;
}

.card-zoom:hover .zoom-img {
  transform: scale(1.05);
}
.close-btn {
  background-color: #ff4c60;
  color: white;
}
.close-btn:hover {
  background-color: #ff4c60;
  color: white;
}
</style>
